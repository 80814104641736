export interface tag {
    label: string;
    color?: string;
}

const tagsCreated = new Map<string,tag>();  

export function newTag(label: string, color: string): tag {
    const t: tag = {label, color};
    tagsCreated.set(label,t);
    return t;
}

export function getCreatedTags(): tag[] {
    return Array.from(tagsCreated.values()).sort(
        (a,b) => (a.color ?? '').localeCompare(b.color ?? '') +
                0.5 * (a.label).localeCompare(b.label))
}