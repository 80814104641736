import { FunctionComponent } from 'react'
import { IDisplayProps } from './Display';
import { Row, Col, Card} from 'antd';
import Meta from 'antd/lib/card/Meta';

const CardDisplay: FunctionComponent<IDisplayProps> = ({name, entries}) =>
    <div className="Section">
        <h3>{name}</h3>
        <Row gutter={8} justify='center'>
            {entries.map(({header, subHeader, href, image}) => 
                <Col className="gutter-row" span={12}>
                    <Card
                    onClick={()=>window.location.href=href || ""}
                    hoverable
                    style={{ height: "100%"}}
                    cover={<img alt="example" src={image} />}
                    >
                    <Meta title={header} description={subHeader} />
                </Card>
            </Col>
            )}
        </Row>
    </div>

export default CardDisplay;

