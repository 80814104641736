import { FunctionComponent } from 'react'
import { IDisplayProps } from './Display';
import Section from '../Section';
import Entry from '../Entry';
import { Divider, Timeline } from 'antd';
// import { MoreOutlined } from '@ant-design/icons'
import { EntryData } from '../../model/Data';

const ListDisplay: FunctionComponent<IDisplayProps> = ({name, entries, setShowEverything}) =>
    <Section name={name} key={name}>  
    {entries.map((entry: EntryData, i: number) => 
    <>
        { <Entry entryData={entry}> 
        <Timeline style={{width:"100%", marginLeft: 10}}> 
            {entry.entries && entry.entries.map((subentry: EntryData) => 
            <Timeline.Item> 
                <Entry entryData={subentry}/> 
            </Timeline.Item> 
            )}
        </Timeline>
        </Entry> }
        {i < entries.length - 1 && <Divider/>}
    </>
    )}
    {/* { (entries.length < entries.length) && <Button type='link' size='large' icon={<MoreOutlined/>} onClick={()=>setShowEverything(true)}/>} */}
    </Section>

export default ListDisplay;
