import { FunctionComponent } from 'react'
import { IDisplayProps } from './Display';
import { Button, Divider, Tag } from 'antd';
import { GithubOutlined} from '@ant-design/icons'
import Section from '../Section';

import {
    isMobile,
} from "react-device-detect";

const ProjectDisplay: FunctionComponent<IDisplayProps> = ({name, entries, setShowEverything}) => {
    var count = 0;
    const ImageComponent = (image: any, href?: string) => <img alt='' src={image} onClick={()=>window.location.href=href || ""} 
        className="shadowImage"
        style={{ maxWidth:"100%"}} 
        />;
        
    return <Section name={name} key={name}>
        {entries.map(({header, image, body, href, secondaryHref, tags}) => 
            <>
            { (count++ > 0) && <Divider/>}
            <h2> {header} </h2>
            { tags && tags.map( t => <Tag color={t.color}>{t.label}</Tag>)}
            <br/>
            { isMobile && <> 
                <br/>
                {ImageComponent(image, href || secondaryHref)}
                <br/>
            </>}
            <br/>
            <div style={{display: "flex", flexDirection:"row"}}>
                { !isMobile && <div style={{flex:3, paddingRight: 25}}>
                    {ImageComponent(image, href  || secondaryHref)}
                    <br/>
                    <br/>
                </div> }
                <div style={{flex:7}}>
                    {body}
                </div>
            </div>
            <div
                style={{display: "flex", flexDirection:"row", justifyContent: "flex-end"}}
                >
                       {href && <Button href={href} 
                            type='primary' 
                            style={{
                            maxWidth: 150,
                            width:"50%",
                            marginRight: 2}}> View Live </Button>}
                        {secondaryHref && <Button href={secondaryHref} 
                            type='primary' 
                            style={{
                                width:"50%", 
                                maxWidth: 200,
                                marginLeft: 2,
                                backgroundColor:"black", 
                                borderColor: "black"}}>
                                <GithubOutlined /> Source Code</Button>}
            </div>
            </>
        )}
    </Section>}


export default ProjectDisplay;
