import { FunctionComponent } from 'react'
import { IDisplayProps } from './Display';
import Entry from '../Entry';
import { Card } from 'antd';
// import { MoreOutlined } from '@ant-design/icons'
import { EntryData } from '../../model/Data';

const RowDisplay: FunctionComponent<IDisplayProps> = ({name, entries, setShowEverything}) =>
    <div className="Section">
        <h3>{name}</h3>
        {entries.map(({image, header, body, href, tags}: EntryData, i: number) => <>
            <Card 
                style={{minHeight: "100px", marginBottom: 7}} 
                hoverable
                onClick={()=>window.location.href=href || ""}>
                <div style={{display: "flex", justifyContent: "space-around" }}>
                    <div style={{maxWidth: "60%"}}>
                        <Entry entryData={{header, body, href, tags}}/> 
                    </div>
                    { image && <div style={{maxWidth: "40%", maxHeight: "100%", overflow: 'hidden', display: "inline-block"}}>  
                        <img alt='' src={image} style={{width:"100%"}} />
                    </div>}
                </div>
            </Card>
        </>)}
        {/* { (entries.length < entries.length) && <Button type='link' size='large' icon={<MoreOutlined/>} onClick={()=>setShowEverything(true)}/>} */}
    </div>

export default RowDisplay;
