import React, { FunctionComponent, ReactElement } from 'react'
import {Row, Col, Tag, Button} from 'antd'
import {tag} from '../model/Tag'
import { LinkOutlined } from '@ant-design/icons';
import { EntryData } from '../model/Data';

import {
  isTablet, isMobile
} from "react-device-detect";

interface IEntryProps {
    entryData: EntryData
    children?: React.ReactNode,
}

const link = (href: string): ReactElement => 
  <Button style={{display: 'inline-block'}} href={href} type="link" icon={<LinkOutlined/>} size='small'/>

const Entry: FunctionComponent<IEntryProps> = 
  ({entryData, children}) => {
  const {header, image, subHeader, upperMinor, lowerMinor, tags, href, body} = entryData

  if (isMobile && !isTablet) {
    return <>
        <>
        {(header || image || upperMinor) && <Row wrap={false} style={{height: "35px", margin: "auto"}}>
          { image && <Col flex="35px">
              <img alt ='' src={image} style={{width: "35px", display: "inline-block", marginRight: "15px", paddingRight: "5px"}}/>
          </Col> }
          {header && <Col flex='auto'> <h3>{header}{href && link(href)}</h3> </Col> }
          {upperMinor && <Col flex={2} style={{textAlign: 'right'}}>{upperMinor}</Col>}
          </Row> }
      </>
      <>
        { (subHeader || lowerMinor) && <Row>
                <Col flex={7}><h4>{subHeader}</h4></Col>
                <Col flex={1} style={{textAlign: 'right'}}>{lowerMinor}</Col>
        </Row> }
      </>
      <> 
          { tags && <div style={{paddingBottom:4}}>
            {tags.map( (t: tag) => <Tag color={t.color}>{t.label}</Tag>)}
          </div> }
      </>
      {body ?? <br/>}
      {children}
    </> 
  }

  return <Row wrap={false}>
  { image && <Col flex="100px">
      <img alt= '' src={image} style={{width: "100px", display: "inline-block", paddingRight: "7px"}}/>
  </Col> }
  <Col flex='auto'>
    <div>
      { (header || upperMinor) && <Row>
          <Col flex="auto">
              <h3 style={{display:'block'}}>{header}{href && link(href)}</h3>
          </Col>
          <Col flex="150px" style={{textAlign: 'right'}}>{upperMinor}</Col>
      </Row> }
      { (subHeader || lowerMinor) && <Row>
          <Col flex="auto"><h4>{subHeader}</h4></Col>
          <Col flex="125px" style={{textAlign: 'right'}}>{lowerMinor}</Col>
      </Row> }
      <Row> 
        { tags && <div style={{paddingBottom:5}}>
          {tags.map( (t: tag) => <Tag color={t.color}>{t.label}</Tag>)}
        </div> }          
      </Row>
      <Row>
          {body}
          {children}
      </Row> 
    </div>
  </Col>
</Row>
}

export default Entry;
