import React, { FunctionComponent } from 'react'
import {Card} from 'antd'

interface ISectionProps {
    name?: string,
    children?: React.ReactNode
}

const Section: FunctionComponent<ISectionProps> = ({name, children}) =>
    <div className="Section">
        {name && <h3>{name}</h3>}
        <Card style={{
            borderRightColor:   'rgb(0, 0, 0, 0.1)',
            borderBottomColor:  'rgb(0, 0, 0, 0.1)',
            borderRightWidth:   '3px',
            borderBottomWidth:  '2px',
        }}>  
            {children}
        </Card>
    </div>

export default Section;
