import { EntryData } from "../../model/Data";
import BlockDisplay from "./BlockDisplay";
import CardDisplay from "./CardDisplay";
import ListDisplay from "./ListDisplay";
import ProjectDisplay from "./ProjectDisplay";
import RowDisplay from "./RowDisplay";

export interface IDisplayProps {
    name?: string,
    entries: EntryData[]
    setShowEverything: any
}

export function GetDisplay(type: String, props: IDisplayProps) {
    if (type === "list") {
        return <ListDisplay {...props}/>
    }
    if (type === "card") {
        return <CardDisplay {...props}/>
    }
    if (type === "row") {
        return <RowDisplay {...props}/>
    }
    if (type === "project") {
        return <ProjectDisplay {...props}/>
    }
    if (type === "block") {
        return <BlockDisplay {...props}/>
    }
}