import './App.css'
import {useState, useEffect} from 'react'
import boidsState from './model/boids.json';

import { isMobile } from "react-device-detect";
import { Layout, Tag, PageHeader, Button } from 'antd'
import Section from './component/Section'
import { getCreatedTags, tag } from './model/Tag'
import {DATA, SectionData} from './model/Data'
import { Avatar } from 'antd';
import { UserOutlined, GithubFilled, LinkedinFilled, FileOutlined} from '@ant-design/icons';
import { Typography } from 'antd';
import {BoidEnviornment} from './boids.js'
import profile_photo from './resources/me.png'
import { GetDisplay } from './component/display/Display'

const { Title } = Typography;

const {Footer, Content } = Layout;

function App() {
  const [showEverything, setShowEverything] = useState(true);
  console.log(showEverything) // use var

  useEffect(() => {
    const canvas = document.getElementById('canvas');
    const boids = new BoidEnviornment(canvas);

    function updateRatio(){
      boids.canvas.width = canvas?.offsetWidth
      boids.canvas.height = canvas?.offsetHeight 
    }
    window.onresize = updateRatio
    updateRatio()

    boids.populate(200)
    boids.play()
    boids.loadPopulation(boidsState)
  })

  return (
    <Layout>
        <PageHeader
          className="site-page-header"
          title={<text style={{color: 'white'}} href="/">🚀 Adam Zawierucha</text>}
          style={{ 
            position: 'fixed', 
            zIndex: 2, 
            width: '100%',
            borderColor: 'black',
            backgroundColor: 'black',
            backgroundImage: 'linear-gradient(315deg, #040404 0%, #212121 74%)'
          }}
          extra={!isMobile && <>
            <Button type="link" href ="https://www.github.com/zawie" style={{color: 'white'}}> <GithubFilled /> GitHub </Button>
            <Button type="link" href ="https://www.linkedin.com/in/zawie" style={{color: 'white'}}> <LinkedinFilled /> LinkedIn </Button>
            <Button type="link" href ="/files/AdamZawieruchaCV.pdf" style={{color: 'white'}}> <FileOutlined /> CV </Button> 
          </>}
        />
      <div id="Landing">
        <canvas id="canvas" style={{ position: "absolute", zIndex: 101, height: "100%", width: "100%" }}/>
        <div id="Banner" style={{zIndex: 102}}>
          <Avatar size={128} icon={<UserOutlined />} src={profile_photo} />
          <br/>
          <div id="NameBanner">
            <Title style={{color:'white'}}>
              Adam Zawierucha
            </Title>
            <text>
              EC2 Software Engineer | Ph.D. Applicant
            </text>
          </div>
        </div>
      </div>
      <Content className="Content">
        <>
        <div className="Section">
          <Button type="link" href ="https://www.github.com/zawie"> <GithubFilled /> GitHub </Button>
          { "|" }
          <Button type="link"  href ="https://www.linkedin.com/in/zawie"> <LinkedinFilled /> LinkedIn </Button>
          { "|" }
          <Button type="link" href="/files/AdamZawieruchaCV.pdf"> <FileOutlined /> CV </Button> 
        </div>
        {DATA.map(({name, entries, type}: SectionData) => 
          GetDisplay(type || "list", {name, entries, setShowEverything})
        )}
        <Section name="📚 Skills">
          <div> 
            {getCreatedTags().map((t: tag) =>
              <Tag color={t.color}>{t.label}</Tag>
            )}
          </div>
        </Section>
        </>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}

export default App;
