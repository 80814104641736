import { FunctionComponent } from 'react'
import { IDisplayProps } from './Display';
import Section from '../Section';
import { EntryData } from '../../model/Data';


const BlockDisplay: FunctionComponent<IDisplayProps> = ({name, entries}) =>
    <Section>
        <h3>{name}</h3>
        {entries.map((entry: EntryData, i: number) => entry.body)}
    </Section>

export default BlockDisplay;